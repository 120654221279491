import React from "react"
import { Helmet } from 'react-helmet';
import Axios from "axios"
export default class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        name: "",
        nameError: false,
        contact: "",
        email: "",
        emailError: false,
        emailError2: false,
        message: "",
        messageError: false,
        formValid: false,
        captchaResponse:"",
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submitData = this.submitData.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.add = this.add.bind(this);
        this.recaptchaSiteKey = "6Le3reYcAAAAAJNE_2exBuax54odN_ag9_VAeqrQ"
    }
    add() {
        this.props.onButtonClick(this.state.value);
        this.setState({ value: '' });
    }
    isValidEmail(email) {
        return /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email);
    }

    isValidcontact(contactno) {
        return /^[6-9]\d{9}$/.test(contactno);
    }

    handleBlur(e) {
            const name = e.target.name;
            const value = e.target.value;

            this.setState({ [name]: value });

            if (value.length <= 0 && name === "name") {
                this.setState({ nameError: true });
            } else {
                this.setState({ nameError: false });
            }

            if (value.length < 0 && name === "email") {
                this.setState({ emailError: false });
            } else {
                this.setState({ emailError: false });
                if (this.isValidEmail(value)) {
                    this.setState({ emailError2: false });
                } else {
                    this.setState({ emailError2: true });
                }
            }
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        const {
        name,
        email,
        contact,
        message,
        nameError,
        emailError,
        emailError2,
        messageError,
        } = this.state;

        this.setState({ nameError: name ? false : true });
        this.setState({ messageError: message ? false : true });
        this.setState({ emailError: email ? false : true });
        if (email && !emailError && !emailError2) {
            this.setState({ emailError2: this.isValidEmail(email) ? false : true });
        }

        if (
        name &&
        email &&
        message &&
        !nameError &&
        !emailError &&
        !emailError2 &&
        !messageError
        ) {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(this.recaptchaSiteKey, { action: 'submit' }).then(token => {
                    this.submitData(token, name, contact, email, message);
                });
            });
            
        } else {
            this.setState({ formValid: false });
        }
    }
    submitData(token, name, contact, email, message ) {
        Axios.post('https://mail.kaanata.com/contact.php',{
            name: name,
            email: email,
            contact: contact,
            message: message,
            gresponse: token,
        }).then((response) => {
            console.log(response);
            this.setState({ formValid: true });
        }, (error) => {
            console.log(error);
            this.setState({ formValid: false });
        });
    }
    render() {
        const {
        name,
        email,
        message,
        contact,
        nameError,
        emailError,
        emailError2,
        messageError,
        formValid,
        } = this.state;

        if (!formValid) {
        return (
            <>
            <Helmet>
                <script
                key="recaptcha"
                type="text/javascript"
                src={`https://www.google.com/recaptcha/api.js?render=${this.recaptchaSiteKey}`}
                />
            </Helmet>
            <form
                    
                    action="/"
                    onSubmit={e => this.handleSubmit(e)}
                    encType="multipart/form-data"
                    autoComplete="off"
                    className="part-contact"
                >
                    <div className="form-group">
                    <label htmlFor="name">
                        Your name<span className="text-danger">*</span>
                    </label>
                    <input
                        id="name"
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        value={name}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                    />
                    {nameError ? (
                        <div className="alert alert-danger mt-2">
                        Name is a required field.
                        </div>
                    ) : (
                        ""
                    )}
                    </div>
                    <div className="form-group">
                    <label htmlFor="email">
                        Your email<span className="text-danger">*</span>
                    </label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                    />
                    {emailError ? (
                        <div className="alert alert-danger mt-2">
                        Email is a required field.
                        </div>
                    ) : (
                        ""
                    )}
                    {emailError2 ? (
                        <div className="alert alert-danger mt-2">
                        Email invalid.
                        </div>
                    ) : (
                        ""
                    )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="contact">Your contact number (Optional)</label>
                    <input
                        id="contact"
                        name="contact"
                        type="text"
                        className="form-control"
                        placeholder="Contact"
                        onChange={this.handleChange}
                        value={contact}
                    />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message">
                            Message<span className="text-danger">*</span>
                        </label>
                        <textarea
                            id="message"
                            name="message"
                            type="text"
                            className="form-control"
                            placeholder="Message"
                            value={message}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                        />
                        {messageError ? (
                            <div className="alert alert-danger mt-2">
                            Message is a required field.
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <p className="mb-0">
                        <input
                            type="submit"
                            className="btn btn-primary btn-lg text-uppercase"
                            value="Submit Now"
                        />
                    </p>
                </form>
            </>
        );
        } else {
            return (
                <div className="thankyou_details row">
                    <div className="col mt-3">
                        <h3 className="text-center">Mail sent successfully.</h3>
                        <div class="success-animation">
                            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                        </div>
                    </div>
                </div>
            );
        }
    }
    }
