import React from "react"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Image } from "react-bootstrap";

const Clients = ({clients}) => {
    const responsive = {
        desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
        slidesToSlide: 3, // optional, default to 1.
        },
        tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 2, // optional, default to 1.
        },
        mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
        },
    }
    return (
        <>
        <Carousel 
            responsive={responsive}
            arrows={false}
            showDots={true}
            autoPlay={true}
            autoPlaySpeed={7000}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            infinite={true}
        >
            {clients.map(client =>(
                <Image key={client.name} src={client.img.localFile.publicURL} alt={client.img.altText} fluid/>
            ))}
        </Carousel>
        </>
    )
}

export default Clients;
