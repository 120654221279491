import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser"
import { Container,Row,Col,Image } from "react-bootstrap";

import Layout from "../components/layout"
import Seo from "../components/seo"
import Clients from "../components/clients"
import ContactForm from "../components/contact-form"
import iconViewAll from "../images/icon-arrow.svg"

const BlogHomePageTemplate = ({ data: { page, fpt } }) => {
  const featuredImage = {
    fluid: page.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: page.featuredImage?.node?.altText || ``,
    id:page.featuredImage?.node?.id || ``
  }
  const lastPortfolios = fpt.edges;
  const skillSet = {
    techs: page.homepage.skillset.techs,
    apps: page.homepage.skillset.apps
  }
  const clientList = page.homepage.clients.clientInfo

  return (
    <Layout>
      <Seo title={page.seo.title} description={page.seo.metaDesc} img="" />
      <Container>
          <Row md={2} xs={1} className="hero">
            <Col md={{order:'first'}}><h1 className="text-header">{parse(page.content)}</h1></Col>
            <Col xs={{ order: 'first' }}>
              {featuredImage?.fluid && (
                <GatsbyImage
                key={featuredImage.id}
                image={featuredImage.fluid}
                alt={featuredImage.alt} />
              )}
              <div className="quote">
                <p className="quote"> "Every block of stone has a statue inside it and it is the task of the sculptor to release it"</p>
                <p className="nameQuote"><i className="nameQuote">Michelangelo di Lodovico Buonarroti Simoni</i></p>
              </div>
            </Col>
          </Row>
          <Row className="part-portolio">
                <Col lg={12} xs={12}>
                  <h2 className="underlined">Last Projects</h2>
                </Col>
                <Col>
                  <Row md={3}>
                  {lastPortfolios.map(portfolio => (
                      <Col keyfield={portfolio.post.id} key={portfolio.post.id}>
                          <Link to={portfolio.post.uri}>
                            <h3>{parse(portfolio.post.title)}</h3>
                            {portfolio.post.featuredImage?.node.localFile.childImageSharp?.gatsbyImageData ? (
                                <GatsbyImage image={portfolio.post.featuredImage.node.localFile.childImageSharp.gatsbyImageData} alt={portfolio.post.featuredImage.node.altText} />
                            ):(
                                <Image src={portfolio.post.featuredImage.node.localFile.publicURL} alt={portfolio.post.featuredImage.node.altText}/>
                            )}
                          </Link>
                      </Col>
                  ))}
                  </Row>
                  <Row>
                      <Col xs={12} lg={12} className="text-center">
                          <br/>
                          <Link to="portfolio" className="btn-viewall"><Image src={iconViewAll} alt="View All Portfolio" width="60px" height="60px" /></Link>
                          <br/>
                      </Col>
                      <Col xs={12} lg={12} className="text-center mb-4">
                        <small><i>If you have password for secret portfolio, you can click <Link to="/login/">here</Link></i></small><br/>
                        <small><i>If you don't have password,please ask me. </i></small>
                      </Col>
                  </Row>
                </Col>
          </Row>
          <Row md={2} xs={1} id="about" className="part-info">
                <Col>
                      <h2 className="underlined">About</h2>
                      <p className="text-about">{parse(page.homepage.aboutme)}</p>
                </Col>
                <Col>
                    <h2 className="underlined">Skills</h2>
                    <Row className="part-skills">
                        {skillSet.techs.map(tech =>(
                          <Col key={tech.altText} className="text-center">
                            {tech.localFile.childImageSharp?.gatsbyImageData ?  (
                                <GatsbyImage image={tech.localFile.childImageSharp.gatsbyImageData} alt={tech.alt} />
                            ):(
                                <Image src={tech.localFile.publicURL} alt={tech.altText} width="48px"/>
                            )}
                            <p>{tech.altText}</p>
                          </Col>
                        ))}
                        {skillSet.apps.map(app =>(
                          <Col key={app.altText} className="text-center">
                            {app.localFile.childImageSharp?.gatsbyImageData ?  (
                                <GatsbyImage image={app.localFile.childImageSharp.gatsbyImageData} alt={app.alt} />
                            ):(
                                <Image src={app.localFile.publicURL} alt={app.altText} width="48px"/>
                            )}
                            <p>{app.altText}</p>
                          </Col>
                        ))}
                    </Row>
                </Col>
          </Row>
          <Row className="part-clients">
            <Col xs={12} lg={12}>
              <h2 className="underlined">Clients</h2>
            </Col>
            <Col>
                <Clients clients={clientList}></Clients>
            </Col>
          </Row>
          <Row id="contact" className="part-contact">
            <Col xs={12} lg={12}>
                <h2 className="underlined">Say Hello</h2>
            </Col>
            <Col className="text-about">
                <p>Looking to start a new project or just want to say hi? Send me an email and I’ll do my best to reply within 24 hrs!</p>
                <p>If contact forms aren’t your thing... send me an email at <b>info@kaanata.com</b></p>
            </Col> 
            <Col>
              <ContactForm title="Contact Form" />
            </Col>
          </Row>
      </Container>
      <hr />
    </Layout>
  )
}

export default BlogHomePageTemplate

export const pageQuery = graphql`
query HomePageById($id: String!) {
  page: wpPage(id: {eq: $id}) {
    id
    content
    title
    date(formatString: "MMMM DD, YYYY")
    featuredImage {
      node {
        id
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
    }
    homepage {
      aboutme
      skillset {
        apps {
          altText
          id
          localFile {
            publicURL
            childrenImageSharp {
              gatsbyImageData
            }
          }
        }
        techs {
          altText
          localFile {
            publicURL
            childrenImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      clients {
        clientInfo {
          ... on WpPage_Homepage_Clients_ClientInfo_ClientItem {
            
            fieldGroupName
            name
            url
            img {
              altText
              localFile {
                childrenImageSharp {
                  gatsbyImageData
                }
                publicURL
              }
            }
          }
        }
      }
    }
    seo {
      metaDesc
      metaKeywords
      title
    }
  },
  fpt:allWpPost(
    limit:3
    sort: {fields: [date], order: DESC}
    filter: {categories: {nodes: {elemMatch: {slug: {eq: "portfolio"}}}}}
  ) {
    edges {
    post: node {
        id
        title
        uri
        featuredImage {
          node {
            altText
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
}
`
